@import "../../css/utils";

$border-color: var(--background);

.page-layout {
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  background-image: var(--global-background);
  .page-top {
    background-color: var(--background);
  }
  &.mobile {
    flex-direction: column;
    height: 100%;
  }
}

.sidebar {
  height: 100%;
  z-index: 15;
  min-width: $sidebar-width;
  overflow: hidden;
  flex: none;
  &.mobile {
    transition: max-height 300ms linear;
    max-height: 0;
    width: 100%;
    height: auto;
    &.open {
      max-height: calc(100% - 64px);
    }
  }
  &.wtr {
    display: none;
  }
}

.backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  opacity: 0.85;
  z-index: 10;
}

.main {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: margin 300ms linear;
  border-radius: 20px 0 0 20px;
  background-color: var(--background);
  &.mobile {
    border-radius: 20px 20px 0 0;
  }
  &.wtr {
    border-radius: unset;
  }

  .content {
    position: relative;
    .inner {
      @include standard-page-content;
    }

    .inner.enlarged {
      max-width: $internal-max-width-enlarged;
    }
  }
}

@include ie11-styles {
  .main {
    .content {
      display: block;
    }
  }
}

html[dir="rtl"] {
  .main:not(.mobile) {
    border-radius: 0 20px 20px 0;

    &.wtr {
      border-radius: unset;
    }
  }
}
