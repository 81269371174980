.wrapper {
  position: relative;
  // margin-bottom: 8px;

  .caption {
    position: absolute;
    top: -8px;
    left: 13px;
    background-color: inherit;
    padding: 0 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.71;
    color: var(--text);
    white-space: nowrap;
    z-index: 1;

    &.outside {
      left: 0;
      top: 0;
      position: relative;
      white-space: normal;
      z-index: auto;
      padding: 0;
      margin-bottom: 8px;
    }
  }

  .mainArea {
    display: flex;
    align-items: center;
    position: relative;

    &:focus {
      border: 4px solid red;
    }
  }

  .errorText {
    margin-top: 2px;
    margin-bottom: 6px;
    font-size: 12px;
    color: var(--error);
    min-height: 18px;
    width: 100%;
    text-align: left;
    line-height: 16px;
  }

  .tip {
    font-size: 12px;
    color: var(--color-3);
    padding-top: 4px;
  }
}

html[dir="rtl"] {
  .wrapper {
    .caption {
      left: unset;
      right: 0;
    }

    .errorText {
      text-align: right;
    }
  }
}
