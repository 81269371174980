.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  .videoWrapper {
    position: relative;
    min-height: 300px;
  }
  video {
    max-width: 100%;
    &.mobile {
      max-height: 50vh;
    }
  }
  .header,
  .footer,
  .error,
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header {
    width: 100%;
    position: relative;
    height: 40px;
    margin-bottom: 20px;
    .backButton {
      cursor: pointer;
      position: absolute;
      left: 10px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 2px;
        width: 24px;
        height: 24px;
      }
    }
  }
  .footer {
    height: 80px;
  }
  .button {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    cursor: pointer;
    background-color: var(--accent);
    svg {
      color: var(--background);
      width: 24px;
      height: 24px;
    }
  }
  .error {
    font-size: 14px;
    color: var(--error);
  }
}
