@import "../../../css/utils";

.title {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: var(--text);

  &.huge {
    font-size: 68px;
  }

  &.smallText {
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  &.mediumText {
    font-size: 42px;
    margin-bottom: 8px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  @media screen and (max-width: $mobile) {
    font-size: 42px;
    &.smallText {
      font-size: 10px;
    }
  }
}
