@import "../../../css/utils";

@mixin float-label {
  font-size: 14px;
  padding: 0 5px;
  background-color: var(--background);
  top: 0;
  left: 0;
  transform: translate(13px, -50%) scale(1);
}

@mixin default-positioned-label {
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: left bottom;
  top: 50%;
  left: 0;
  transform: translate(18px, -50%) scale(1);
  max-width: calc(100% - 20px);
}

@mixin error-positioned-label {
  width: calc(100% - 60px);
}

input.floatedInput {
  // &::placeholder,
  // &::-webkit-input-placeholder {
  //   opacity: 0;
  // }

  // IE11 selector
  &:-ms-input-placeholder {
    color: transparent;
  }

  // IE11 selector
  &:-ms-input-placeholder+label {
    @include default-positioned-label();
  }

  // &:placeholder-shown + label {
  //   @include default-positioned-label();
  // }

  // IE11 selector
  &:not(:-ms-input-placeholder)+label {
    @include float-label();
  }

  // &:focus + label {
  //   @include float-label();
  // }

  &:not(:placeholder-shown)+label {
    @include float-label();
  }

  &.error {

    // IE11 selector
    &:-ms-input-placeholder+label {
      @include error-positioned-label();
    }

    &:placeholder-shown+label {
      @include error-positioned-label();
    }

    &:focus+label {
      width: unset;
    }
  }

  // should be separate css selector, not to break floating in firefox
  &:-webkit-autofill+label {
    @include float-label();
  }
}

input.stickedInput {
  &+label {
    @include float-label();
  }
}

.input {
  width: 100%;

  &.error {
    border-color: var(--error);
    box-shadow: none;
  }

  // to fix autofill settings in webkit
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
    font-size: 16px;
    -webkit-text-fill-color: var(--text);
    -webkit-box-shadow: 0 0 0 30px var(--background) inset !important;
  }
}

.hint {
  @include hint;
  flex: 0 0 auto;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);

  @media screen and (max-width: $mobile) {
    right: 5px;
  }
}

.passwordButton,
.validationIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  // &.hidden {
  //   visibility: hidden;
  // }
}

.input-field {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;

  label {
    color: var(--color-4);
    font-size: 16px;
    position: absolute;
  }

  .exclamation {
    width: 23px;
    height: 20px;
  }

  .eye {
    cursor: pointer;
    color: var(--text);
    opacity: 0.5;
    width: 24px;
    height: 24px;
  }
}

html[theme="dark"] {
  .input-field {
    .eye {
      path {
        fill: white;
      }
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 10;
  transform: translateY(calc(-50% + 27px));

  &.left::after {
    position: absolute;
    content: "";
    border-left: 8px solid var(--color-7);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: -8px;
    top: 50%;
    margin-top: -5px;
  }

  &.right::after {
    position: absolute;
    left: -8px;
    content: "";
    border-right: 8px solid var(--color-7);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    top: 50%;
    margin-top: -5px;
  }

  &.bottom {
    transform: translateY(65px);
    left: 0;
    width: 100%;
    max-width: unset;

    &::after {
      position: absolute;
      left: 50%;
      top: -6px;
      content: "";
      border-style: solid;
      border-width: 0 4px 6px 4px;
      border-color: transparent transparent var(--accent) transparent;
    }
  }
}

@mixin rtl-default-positioned-label {
  left: unset;
  transform: translate(-18px, -50%) scale(1);
}

@mixin rtl-shown-label {
  left: unset;
  transform: translate(-13px, -50%) scale(1) !important;
}

html[dir="rtl"] {
  .input-field {
    .hint {
      margin-left: unset;
      margin-right: 20px;
      right: unset;
      left: -30px;
    }

    input.ltr {
      direction: ltr;

      &::placeholder {
        text-align: right;
      }
    }

    .passwordButton:not(.ltr),
    .validationIcon:not(.ltr) {
      position: absolute;
      right: unset;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    input.floatedInput:not(.ltr) {
      &:placeholder-shown+label {
        @include rtl-default-positioned-label();
      }

      &:not(:placeholder-shown)+label,
      &:focus+label {
        @include rtl-shown-label();
      }

      // IE11 selectors
      &:-ms-input-placeholder+label {
        @include rtl-default-positioned-label();
      }

      &:not(:-ms-input-placeholder)+label,
      &:focus+label {
        @include rtl-shown-label();
      }
    }
  }
}

@include ie11-styles {

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}