@import "../../css/utils";

$gap: 10px;

.item {
  padding: $gap 20px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: auto;
  grid-gap: $gap;

  @media screen and (max-width: $mobile) {
    grid-template-columns: auto;
    padding: $gap 0;
  }

  &:hover {
    background-color: var(--highlight);
  }

  .content {
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: auto;
    grid-gap: $gap;
    align-items: center;
  }

  .link {
    text-decoration: none;

    @media screen and (max-width: $mobile) {
      // to stretch the button inside the 100% of given width
      display: grid;
    }
  }

  .icon {
    height: auto;
    width: 32px;
    display: block;
  }

  .text {
    justify-self: start;
  }
}

@include ie11-styles {
  .item {
    .text {
      display: flex;
      align-items: center;
    }
  }

  .link {
    display: block;
  }
}
