.qrCode {
    display: flex;
    font-size: smaller;
    &.desktop {
        gap: 16px;
        align-items: center;
        .qr > img {
            width: 96px;
            height: 96px;
        }
    }
    &.mobile {
        gap: 8px;
        flex-direction: column;
        > div {
            display: block;
        }
    }
}
