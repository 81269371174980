.row {
  display: flex;
  align-items: center;
}
.wrapper {
  margin-bottom: 0;
  margin-top: -2px;
}
.menu {
  position: relative;
}
.triangle {
  position: absolute;
  top: -1px;
  right: calc(50% - 20px);
  width: 12px;
  height: 12px;
  background-color: var(--background);
  border: 1px solid transparent;
  border-left-color: var(--color-5);
  border-top-color: var(--color-5);
  transform-origin: center;
  transform: rotate(45deg) translate(50%);
  z-index: 20;
}
.language {
  display: flex;
  align-items: center;
}
.flags {
  margin-inline-end: 5px;
}
