@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 300;
  src: url('NotoSansSC-Light.ttf');
}

@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 400;
  src: url('NotoSansSC-Regular.ttf');
}

@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSansSC-Medium.ttf');
}

@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSansSC-Bold.ttf');
}