@import "../../../css/utils";

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--text);

  .icon {
    width: 75px;
    height: 66px;
    margin-bottom: 30px;
  }

  .title {
    font-size: 16px;
    line-height: 1.41;
    text-align: center;
    color: var(--text);
    margin-bottom: 30px;
    width: 100%;
  }
}

.bottomButton,
.bottomButtons {
  margin-top: 50px;
}

.bottomButtons {
  @include grid2;
}
