@import "../../css/utils";

.mobile-header {
  min-height: 64px;
  color: var(--background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 0;
  z-index: 9;
}
.bars {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.menu {
  width: 32px;
  height: 32px;
  background: url(../../resources/images/menu.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logo {
  padding: 8px 24px 8px 16px;
  border-radius: 0 100px 100px 0;
  background-color: var(--color-7);
}

html[dir="rtl"] {
  .mobile-header {
    padding: 10px 0 10px 10px;
  }
  .logo {
    padding: 12px 16px 12px 24px;
    border-radius: 100px 0 0 100px;
  }
}
