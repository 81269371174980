@import "../../css/utils";

.container {
  width: 500px !important;
  @include flex-col;
  padding: 50px;
  @media screen and (max-width: $tablet) {
    width: 300px !important;
    img {
      transform: scale(0.5);
    }
  }
}
