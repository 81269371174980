@import "../../../../../css/utils";

.upload {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 15px 34px 15px 26px;
  @include inner-card;
  font-size: 16px;
  opacity: 1;
  color: var(--text);
  margin-bottom: 24px;
  width: 100%;

  .icon {
    width: 50px;
    height: 50px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin-inline-end: 23px;
  }
}

.hidden {
  opacity: 0;
  height: 1px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.trustdock {
  position: relative;
}
