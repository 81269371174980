@import "../../css/utils";

.page {
  background-color: var(--background);
  height: 100%;
  overflow: scroll;
  .header {
    background-color: var(--background);
    display: flex;
    justify-content: center;
    padding: 30px 0;
    font-size: 26px;
  }

  .data {
    padding: 0 50px;
  }

  .row {
    border-bottom: 2px solid var(--background);
    padding: 20px 0;
  }

  .label {
    color: var(--text);
  }

  .value {
    font-size: 20px;
  }

  .denied {
    color: var(--error);
  }
  .confirmed {
    color: var(--success);
  }
}
