@mixin light-theme {
    --background: #f9f9f9;
    --background-disabled: #e6e8ea;
    --error: #df1a3c;
    --success: #8fc250;
    --text: #0e1d31;
    --color-2: #3e4a5a;
    --color-3: #6e7783;
    --color-4: #9fa5ad;
    --color-5: #cfd2d6;
    --color-6: #e6e8ea;
    --color-7: #00484f;
    --highlight: #3d6b78;
    --highlight-1: #99cfd9;
    --highlight-2: #effcef;
    --highlight-3: #f0fcf0;
    --accent: #138518;
    --backdrop: rgba(white, 0.8);
    --contrast-background: #fff;
    --contrast-text: #f9f9f9;
    --global-background: linear-gradient(155deg, #267871 0%, #136a8a 100%);
    --global-banner-color: #0086a0;
    --global-banner-background: #cce7ec;
    --global-banner-border: #cce7ec;
    //cards
    --card-banner-background: var(--highlight-1);
    --box-shadow: rgba(0, 0, 0, 0.08);
    --active-card-border: rgba(19, 133, 24, 0.5);
    --card-border: none;
    --card-background: #ffff;
    --login-card-background-image: linear-gradient(122deg, rgba(255, 255, 255, 0.4) 14%, rgba(255, 255, 255, 0.1) 86%);
    --alert-header-background: var(--text);
    --alert-header-text: var(--background);
    --inside-box-background: #e5f2f5;

    //inner cards
    --inner-card-background: var(--background);
    --inner-card-hilight-background: var(--background);
    --inner-card-border-color: #e6e8ea;
    --inner-card-border: 1px solid var(--inner-card-border-color);

    //modal
    --modal-header-background: #e6e8ea;
    --modal-header-color: var(--text);
    --modal-error-border: none;
    --modal-border: 1px solid var(--color-5);
    // general inputs
    --input-border-color: #9fa5ad;
    --input-border-color-focused: #0e1d31;
    //checkbox
    --checkbox-checked-background: var(--text);
    --checkbox-checked-checkmark-color: var(--background);
    //radio
    --radio-border-color: var(--color-5);
    --radio-selected-color: var(--text);
    --radio-hover: var(--text);
    --radio-disabled: var(--color-5);
    //select
    --selection-hover: #e7f2e7;
    --selection-hover-color: var(--text);
    --selected-background: var(--accent);
    --selected-color: #f9f9f9;
    --select-out-of-form-menu-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
    --select-out-of-form-menu-border: none;
    //buttons
    --button-hover: #1a671e;
    --button-background: var(--accent);
    --button-color: #f9f9f9;
    --button-border: none;
    --button-disabled: #9fa5ad;
    //sidebar
    --logo-background: #00484f;
    --sidebar-text: #f9f9f9;
    --sidebar-active-submenu: rgba(249, 249, 249, 0.15);
    --sidebar-separator: rgba(255, 255, 255, 0.3);
    --sidebar-active-link-background: #0e1d31;
    --sidebar-active-link-color: #f9f9f9;
    //loader
    --loader-main: #00484f;
    --loader-symbols: #fff;
    //slider
    --slider-background: #9fa5ad;
    --slider-handle: #f9f9f9;
    //pagination
    --pagination-active-page: #3e4a5a;
    //table
    --table-header-background: #e6e8ea;
    --table-body-background: var(--contrast-background);
    --table-hover-background: var(--background);
    --table-row-border: 1px solid #e9ebeb;
    //misc
    --disabled-opacity: 1;
}
