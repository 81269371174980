@import "../../../../css/utils";

.baseView {
  @include flex-col;
  height: 100vh;
  overflow-y: scroll;

  @media screen and (max-width: $mobile) {
    height: 100%;
  }

  .header {
    width: 100%;
    max-width: 552px;
    display: flex;
    justify-content: space-between;
    img.logo {
      width: 195px;
    }
  }
  .content {
    margin: 48px auto 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: none;
    align-items: center;
    gap: 32px;

    @media screen and (max-width: $mobile) {
      padding: 15px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .mainContent {
    padding: 40px 56px;
    @media screen and (max-width: $mobile) {
      padding: 16px 24px;
    }
  }
}

.chat {
  z-index: 100;
  position: sticky;
  bottom: 120px;
  transform: translate(140px, -24px);
}

html[dir="rtl"] {
  .chat {
    transform: translate(-140px, -24px);
  }
}
