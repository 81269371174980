.dropdown {
  cursor: pointer;
  display: inline;
}

.dropdown:focus,
.dropdown *:focus {
  outline: none;
}

.rtl {
  direction: rtl;
}
