.warningButtons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 30px;
}
.newPlatforms {
    span {
        margin-inline-start: 16px;
    }
}
