@import "../../../../css/utils";

@mixin active-submenu {
  background: var(--sidebar-active-submenu);
}

.submenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: transform 300ms;
  color: var(--sidebar-text);
  min-height: 48px;
  padding: 0 18px;
  font-size: 16px;

  &:not(.disabled):hover {
    span,
    svg {
      font-weight: bolder;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.active {
    font-weight: bolder;
    @include active-submenu();
  }
}

.active-submenu {
  @include active-submenu();
}

.submenu.open > .chevron {
  transform: rotate(180deg);
}

.chevron {
  color: var(--sidebar-text);
  margin-inline-start: 16px;
  transition: transform 300ms;
}

.caption {
  display: flex;
  align-items: center;

  span {
    @include extra-space-for-bold;
  }
}

.icon {
  width: 24px;
  height: 24px;
  margin-inline-end: 16px;

  @media screen and (max-width: $mobile) {
    width: 32px;
    height: 32px;
  }

  img {
    width: 24px;
    height: 24px;

    @media screen and (max-width: $mobile) {
      width: 32px;
      height: 32px;
    }
  }
}

@include ie11-styles {
  .submenu {
    height: 48px;
  }

  .icon {
    margin-right: 16px;
  }
}
