@import "../../../../css/utils";

@mixin active-submenu {
  background: var(--sidebar-active-submenu);
}

.link {
  display: flex;
  align-items: center;
  color: var(--sidebar-text);
  transition: transform 300ms;

  svg {
    fill: var(--sidebar-text);
    color: var(--sidebar-text);
  }

  &.active {
    font-weight: bolder;
    background-color: var(--sidebar-active-link-background);
    color: var(--sidebar-active-link-color);
  }
}

.caption {
  display: flex;
  align-items: center;

  .captionContent {
    transition: font-weight 0.2s ease;
    margin-right: -0.1em;
    max-width: 190px;
    white-space: normal;
    word-wrap: break-word;
  }
}

.mainLink,
.nestedLink {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(.disabled):hover {
    span,
    svg {
      font-weight: bolder;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    &.keepAppearance {
      opacity: 1;
    }
  }
}

.mainLink {
  min-height: 48px;
  padding: 0 18px;
  font-size: 16px;

  .icon {
    width: 24px;
    height: 24px;
    margin-inline-end: 16px;

    @media screen and (max-width: $mobile) {
      width: 32px;
      height: 32px;
    }

    svg,
    img {
      width: 24px;
      height: 24px;

      @media screen and (max-width: $mobile) {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.nestedLink {
  min-height: 48px;
  padding-inline-start: 57px;
  padding-top: 8px;
  padding-bottom: 8px;

  &.withIcon {
    padding-inline-start: 22px;

    .icon {
      width: 18px;
      height: 13px;
      margin-inline-end: 6px;
    }
  }
}

@include ie11-styles {
  .mainLink {
    height: 48px;

    .icon {
      margin-right: 16px;
    }
  }

  .nestedLink {
    height: 49px;
    padding-left: 57px;

    &.withIcon {
      padding-left: 22px;

      .icon {
        margin-right: 6px;
      }
    }
  }
}
