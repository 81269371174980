@import "../../../../css/utils";

.login-form {
  position: relative;

  .email-confirmed {
    @include base-card;
    padding: 20px;
    margin-top: -50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    color: var(--highlight);

    svg {
      margin-right: 30px;
      height: 32px;
      width: 32px;

      circle {
        fill: var(--success);
      }
    }
  }

  .forgottenPassword {
    position: absolute;
    right: 0;
    z-index: 1;
    margin-top: 4px;
    font-size: 14px;
    cursor: pointer;
    color: var(--accent);
    font-weight: 600;
  }

  .autologout {
    margin-bottom: 30px;
    color: var(--error);
  }
}

.loginButton {
  @include button(true);
  margin-bottom: 24px;
  display: flex;
}

.pass-error {
  height: 50px !important;

  div {
    white-space: normal !important;
  }
}

.bottom-controls {
  margin-top: 30px;
  @include flex-col;
}

.footer {
  text-align: center;
  color: var(--text);
  font-size: 10px;
}

.maintenance {
  font-size: 12px;
  color: var(--highlight);
  margin: 16px 0;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px var(--background);
  background-color: var(--background);
}

.small {
  font-size: 12px;
}
.disabled {
  pointer-events: none;
  span {
    opacity: 0.5;
  }
}

.ad {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    > svg {
      margin-inline-end: 16px;
    }
  }
  .bigQR {
    > img {
      width: min(400px, 90vw);
    }
    z-index: 10;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
  }
  &:hover .bigQR {
    visibility: visible;
  }
}

html[dir="rtl"] {
  .login-form {
    .forgottenPassword {
      left: 0;
      right: unset;
    }
  }
  .chat {
    right: unset;
    left: -100px;
  }
}
