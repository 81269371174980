@import "../../../css/utils";

.error-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1.41;
  color: var(--text);

  a {
    color: var(--accent);
  }

  .error {
    width: 76px;
    height: 77px;
    color: var(--error);
  }

  .alert {
    width: 75px;
    height: 66px;
  }

  .success {
    color: var(--accent);
  }

  .text {
    margin-bottom: 30px;
    text-align: center;
  }
}

@include ie11-styles {
  .error-modal {
    @include ie11Modal;
  }
}
