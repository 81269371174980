@import "../../../css/utils";

.fixed {
  position: fixed;
  bottom: 92px;
  right: 40px;
  z-index: 100;
  @media screen and (max-width: $tablet) {
    bottom: 72px;
    right: 20px;
  }
}

.horizontal,
.vertical {
  position: relative;
}

.live-chat-icon {
  width: 38px;
  height: 38px;
}

.live-chat-button {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  width: 64px;
  height: 64px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: rgb(36 36 36 / 15%) 0px 20px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-7);
}

.social-links {
  position: absolute;
  z-index: 0;
  border-radius: 37px;
  opacity: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-out;
  box-shadow: 0 0 15px 0 var(--box-shadow);
  border: solid 1px var(--background);
  background-color: var(--card-background);

  &.opened {
    opacity: 1;
    transition: all 0.5s ease-out;
  }
}

.social-icon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: var(--text);
}

.chat-now {
  width: 39px;
}

.horizontal {
  .social-links {
    top: 0;
    right: 0;
    height: 64px;
    padding: 16px 64px 16px 16px;
    width: 0;
    flex-flow: row nowrap;

    &.opened {
      width: 350px;
    }
  }

  .social-icon {
    margin-inline-end: 8px;
  }
  .social-divider {
    margin-inline: 8px 16px;
    height: 100%;
    display: inline-block;
    border-right: 1px solid var(--color-3);
  }
}

.vertical {
  .social-links {
    bottom: -64px;
    right: 0;
    width: 64px;
    padding: 16px 16px 64px 16px;
    height: 0;
    flex-flow: column;
    align-items: center;

    &.opened {
      height: 328px;
    }
  }

  .social-icon {
    margin-block-end: 8px;
  }
  .social-divider {
    margin-block: 8px 16px;
    width: 100%;
    border-bottom: 1px solid var(--color-3);
  }
}

html[dir="rtl"] {
  :global body > div:has(iframe:not([title="No content"])) {
    left: 15px !important;
    bottom: 15px !important;
    right: unset !important;
  }

  .fixed {
    right: unset;
    left: 46px;
  }
  .horizontal {
    .social-links {
      right: unset;
      left: 0;
      padding: 16px 16px 16px 64px;
    }

    .live-chat-button {
      right: unset;
      left: 0;
    }
  }
  .vertical {
    .social-links {
      right: unset;
      left: 0;
    }
    .live-chat-button {
      right: unset;
      left: 0;
    }
  }
}

.zendeskChat {
  font-size: 16px;
  font-weight: 600;

  color: var(--accent);
  display: flex;
  cursor: pointer;

  .zendeskCaption {
    text-decoration: underline;
    margin: 0 8px;
  }

  .zendesk-icon,
  .arrow-icon {
    width: 24px;
    height: 24px;
    margin: 0;
  }
}

html[theme="dark"] {
  .zendesk-icon {
    path {
      fill: var(--text);
    }
  }
}
