@import "../../css/utils";

.header {
  background-color: var(--background);
  padding-top: 30px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $mobile) {
    padding: 16px 0;
  }
}

.inner {
  @include standard-width;

  .content {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $mobile) {
      align-items: flex-start;
    }

    .left {
      flex: auto;
    }

    .right {
      display: flex;
      gap: 32px;
      align-items: center;

      @media screen and (max-width: $mobile) {
        margin-top: 8px;
      }
    }

    .wtr {
      display: none;
    }
  }
}

.ibButton {
  display: flex;
  gap: 10px;
  flex: none;
}

.page-title {
  font-size: 42px;
  line-height: 1.14;
  font-weight: 600;
  overflow: hidden;
  color: var(--text);

  @media screen and (max-width: $mobile) {
    font-size: 32px;
  }
}

.user-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  color: var(--text);
  cursor: pointer;
  text-transform: capitalize;
  svg {
    path {
      fill: var(--text);
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &-icon {
    margin-left: 5px;
    width: 11px;
    height: 7px;
    color: var(--text);
  }

  &-logo {
    width: 28px;
    height: 28px;
    margin-inline-end: 10px;
    font-size: 11px;
    font-weight: 500;
  }
}

.link {
  text-decoration: none;
}

.livechat {
  margin-inline-end: 15px;
}

.back {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.41;
  color: var(--text);
  cursor: pointer;
}

.arrow-back {
  width: 7.4px;
  height: 12px;
  color: var(--text);
  margin-right: 10px;
}

html[dir="rtl"] {
  .user-menu {
    &-logo {
      margin-left: 10px;
    }
  }
}

@include ie11-styles {
  .header {
    .desktop {
      height: 68px;
    }
  }

  .user-menu {
    &-logo {
      margin-right: 10px;
    }
  }
}
