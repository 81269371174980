/* autoprefixer grid: autoplace */
@import "../../node_modules/mq-scss/mq";
@import "../../node_modules/gutter-grid/grid-mixin";

$container-min-width: 400px;
// standard content width section
$container-width: 90%;
$internal-max-width: 1038px;
$internal-max-width-enlarged: 1056px;
$out-of-platform-max-width: 1044px;
// end
$container-top-margin: 50px;
$card-max-width: 460px;
$card-standard-width: 505px;
$card-small-width: 300px;
$input-height: 48px;
$sidebar-width: 238px;
$laptop: 1200px;
$tablet: 900px;
$mobile: 600px;
$small-mobile: 450px;
$control-margin: 8px;
$default-border-radius: 4px;

@mixin button($large: true) {
  @if $large {
    min-width: 168px;
    min-height: 48px;
    padding: 12px 24px;
    font-size: 20px;
  } @else {
    min-width: 128px;
    min-height: 40px;
    padding: 8px 24px;
    font-size: 16px;
  }

  display: flex;
  border-radius: $default-border-radius;
  outline: none;
  border: 0;
  overflow: hidden;
  font-weight: bold;
  justify-content: center !important;
  text-align: center;
  align-items: center;
  cursor: pointer;
  color: var(--button-color);
  background-color: var(--button-background);
  border: var(--button-border);

  &:disabled {
    background-color: var(--button-disabled);
    pointer-events: none;
  }

  &:hover {
    background-color: var(--button-hover);
  }

  &.link {
    color: var(--accent);
    display: flex;
    text-decoration: underline;
    background-color: transparent;
    min-width: auto;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 16px;
    padding: 0px;

    &.no-decoration {
      text-decoration: none;
    }

    &:hover {
      font-weight: bolder;
    }

    &:disabled {
      background-color: var(--background);
      color: var(--color-4);
    }
  }

  &.link-2 {
    color: var(--accent);
    display: flex;
    background-color: var(--background);
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
  }
}

// $mobileReverse is used to reverse buttons (common use-case) on mobile devices
// to make call-to-action button apear first
@mixin grid2($gridGap: 30px, $mobileReverse: false) {
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-gap: $gridGap;
  justify-items: center;

  @if $mobileReverse {
    & > :last-child {
      order: -1;
    }
  }

  @media screen and (min-width: $tablet) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-items: unset;

    @if $mobileReverse {
      & > :last-child {
        order: 0;
      }
    }
  }
}

@mixin grid2-1 {
  width: 100%;
  display: grid;
  grid-gap: 2vw;

  @media screen and (min-width: $mobile) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 20px 0 var(--box-shadow);
}

@mixin column($margin-top: $container-top-margin, $max-width: $internal-max-width, $a-width: $container-width) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $margin-top;
  max-width: $max-width;
  width: $a-width;
}

@mixin colored-link($color: inherit) {
  a {
    &:link,
    &:hover,
    &:active,
    &:visited,
    &:-webkit-any-link,
    &:any-link {
      color: $color;
    }

    color: $color;
  }
}

@mixin link-no-decoration {
  @include colored-link;

  a {
    text-decoration: none;
  }
}

@mixin accent-links {
  @include colored-link(var(--accent));
}

@mixin link-button {
  @include button;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  @include link-no-decoration;
}

@mixin flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin reverse-box-media {
  @media screen and (max-width: $tablet) {
    flex-direction: row;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column-reverse;
  }
}

@mixin standard-width {
  width: $container-width;
  max-width: $internal-max-width;
}

@mixin out-of-platform-standard-width {
  width: $container-width;
  max-width: $out-of-platform-max-width;

  @media screen and (max-width: $mobile) {
    width: 100%;
    max-width: unset;
  }
}

@mixin modal {
  max-width: 592px;
  width: 100%;
  max-height: 90vh;
  overflow: hidden;

  @media screen and (max-width: $mobile) {
    max-width: 90vw;
  }
}

@mixin ie11Modal {
  width: 592px;
  max-height: 90vh;
  overflow: auto;
}

@mixin mobile-paddings {
  @media screen and (max-width: $mobile) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

// cards
@mixin card {
  padding: 30px;
  border-radius: 16px;
  box-shadow: 2px 2px 10px 0 var(--box-shadow);
  border: var(--card-border);
  background-color: var(--card-background);

  @media screen and (max-width: $mobile) {
    padding: 16px;
  }
}

@mixin inner-card {
  @include card;
  border-radius: 8px;
  border: var(--inner-card-border);
  background-color: var(--inner-card-background);

  @media screen and (max-width: $mobile) {
    padding: 16px;
  }
}

@mixin base-card {
  @include card;
  padding: 32px;
  box-shadow: 2px 2px 20px 0 var(--box-shadow);
}

@mixin base-ie-card {
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px var(--box-shadow);
  border: var(--card-border);
  background-color: var(--card-background);
}

//------------ cards

@mixin cards-grid($mobilePaddings: false) {
  display: grid;
  /* autoprefixer: ignore next */
  grid-template-columns: repeat(auto-fill, $card-standard-width);
  grid-gap: 20px;
  justify-content: center;

  @if $mobilePaddings {
    @include mobile-paddings;
  }

  @media screen and (max-width: $mobile) {
    grid-gap: 24px;
    /* autoprefixer: ignore next */
    grid-template-columns: minmax(auto, $card-standard-width);
  }
}

@mixin fill-parent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin standard-page-content {
  margin: 0 auto;
  @include standard-width;

  @media screen and (max-width: $mobile) {
    width: 100%;
    padding: 0 15px;
  }
}

@mixin hint {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  color: var(--background);
  background-color: var(--color-7);
  user-select: none;
  cursor: default;
}

@mixin no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin ie11-styles {
  html[mode="ie11"] {
    @content;
  }
}

@mixin rtl-styles {
  html[dir="rtl"] {
    @content;
  }
}

@mixin standard-content {
  width: 65%;
  padding: 15px;

  @media screen and (max-width: $tablet) {
    width: 100%;
  }
}

@mixin standard-form($padding: 55px 0) {
  position: relative;
  max-width: $card-max-width;
  margin: 0 auto;
  padding: $padding;
}

@mixin extra-space-for-bold {
  &::after {
    display: block;
    content: attr(data-text);
    font-weight: bold;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
}

@mixin layout-columns {
  display: grid;
  grid-template-columns: minmax(100px, 750px) minmax(100px, 234px);
  grid-gap: 52px;

  @media screen and (max-width: $tablet) {
    display: block;

    > div:last-child {
      margin-top: 32px;
    }
  }
}

@mixin accounts-table {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1.5fr repeat(4, 1fr);
}

@mixin no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin tips-card($maxWidth: $card-max-width) {
  max-width: $maxWidth;
  width: 100%;
  align-self: flex-start;

  @media screen and (max-width: $tablet) {
    max-width: unset;
  }
}

@mixin path-fill($color) {
  path {
    fill: $color;
  }
}

@mixin grid2col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 32px;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
