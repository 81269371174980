@import "../../../css//utils";

$horizontal-margin: 12px;

.checkbox {
  display: flex;
  flex: 1;
  align-items: center;

  &.success {
    .rectangle {
      border-color: var(--checkbox-checked-background);
      background-color: var(--checkbox-checked-background);
    }
  }

  &.error {
    .rectangle {
      border-color: var(--error);
    }

    .rectangle:hover {
      border-color: var(--error);
    }
  }

  &.disabled {
    &:hover {
      .rectangle {
        border-color: var(--color-4);
      }
    }

    .rectangle {
      background-color: var(--background);
      border-color: var(--color-4);
      cursor: default;
    }

    .text {
      color: var(--color-3);
    }
  }

  .rectangle {
    width: 24px;
    height: 24px;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: solid 1px var(--color-5);
    cursor: pointer;
    background-color: var(--background);

    &.top {
      align-self: flex-start;
    }

    .checkmark {
      svg {
        g,
        path,
        mask {
          // stroke: var(--checkbox-checked-checkmark-color);
          fill: var(--checkbox-checked-checkmark-color);
        }
      }
    }

    &:hover {
      border-color: var(--text);
    }
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: var(--text);
    margin-inline-start: $horizontal-margin;

    p:first-child {
      margin-top: 0;
    }
  }
}
