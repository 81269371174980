@import "../../../css/utils";

.app {
  height: 100%;
}

.initError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: darkred;
  font-size: x-large;
  height: 100%;

  > div:nth-child(2) {
    margin-top: 50px;
  }
  span {
    width: 80%;
  }
  .errorText {
    text-align: center;
    max-width: 600px;
  }
}

.error {
  margin: 30px auto;
  color: darkred;
  overflow: auto;
  min-height: 600px;
  @include base-card;
}
.contact {
  color: var(--highlight);
}
