.value {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  &.multiValue {
    flex-direction: row;
    flex-wrap: wrap;

    input {
      height: 26px;
    }
  }
}

.highlight {
  background-color: var(--highlight);
  color: var(--background);
}

.field {
  width: 100%;
  position: relative;
}

.search-icon {
  width: 24px;
  height: 24px;
  margin-inline-end: 6px;
  position: relative;
  svg {
    position: absolute;
    top: 2px;
    path {
      fill: var(--color-4);
    }
  }
}
