@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-Light.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-Light.woff?v=3.18") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-LightItalic.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-LightItalic.woff?v=3.18") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-Regular.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-Regular.woff?v=3.18") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-Italic.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-Italic.woff?v=3.18") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-SemiBold.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-SemiBold.woff?v=3.18") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-Bold.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-Bold.woff?v=3.18") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../resources/fonts/Inter/Inter-BoldItalic.woff2?v=3.18") format("woff2"),
    url("../resources/fonts/Inter/Inter-BoldItalic.woff?v=3.18") format("woff");
}