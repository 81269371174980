@import "../../../../../css/utils";

@mixin surface {
    color: var(--surface-color);
    background-color: var(--surface-background);
    border: 1px solid var(--surface-border);
    border-radius: 16px;
}
.mobile {
    --background: #ffffff;
    --accent: #299310;
    --text: #19202d;
    --surface-background: #f8f9fb;
    --surface-border: #eaeef2;
    --surface-color: #5b6676;

    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background);
    .caption {
        font-size: 26px;
        font-weight: bold;
    }
    .text {
        color: #4c5a6b;
    }
    > div {
        display: grid;
        justify-items: center;
        gap: 10px;
        width: 100%;
    }
    .userImage {
        margin-top: 64px;
        > img:nth-child(2) {
            transform: translate(-16px, -100px);
        }
    }
    .tryAgain {
        text-align: center;
        img {
            width: 100%;
        }
    }
    button.green,
    label.outline,
    button.outline {
        all: unset;
        width: 100%;
        border-radius: 24px;
        min-height: 48px;
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }
    button.green {
        background-color: var(--accent);
        color: #fff;
    }
    label.outline,
    button.outline {
        border: 1px solid var(--text);
    }
    .fileIcon {
        color: #ffffff;
    }
    .surface {
        @include surface;
        padding: 16px;
        overflow: hidden;
        span {
            font-weight: bold;
            color: var(--text);
        }
    }
    .divider {
        text-align: center;
        position: relative;
        width: 100%;
        color: #99a3b2;
        z-index: 2;
        span {
            padding: 0 16px;
            background-color: var(--background);
        }
        &::before {
            content: "";
            border-top: 1px solid #d9dee6;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            z-index: -1;
        }
    }
    .imagePreview,
    .pdfPreview,
    .pdfError,
    .error {
        height: 232px;
        width: 100%;
        @include surface;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        text-align: center;
        overflow: hidden;
    }
    .imagePreview img {
        max-width: 100%;
        max-height: 100%;
    }

    .pdfPreview {
        border-color: var(--accent);
    }
    .imagePreview.fullScreen,
    .pdfPreview.fullScreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
    }
    .error,
    .pdfError {
        border-color: var(--error);
        color: var(--error);
    }
    .error img {
        max-width: 100%;
        max-height: 60%;
    }
}

html[theme="dark"] {
    .mobile {
        --background: #19202d;
        --accent: #24b502;
        --text: #ffffff;
        --surface-background: #222a36;
        --surface-border: #2d3643;
        --surface-color: #b3bcc8;

        .text {
            color: #bdc6d0;
        }

        .divider {
            color: #6a7585;
            &::before {
                border-top: 1px solid #424c5b;
            }
        }
    }
}
