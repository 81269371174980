@import "../../css/utils";

.content {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;

  p {
    margin: 0 0 16px;
  }
}

.consent {
  max-height: 400px;
  overflow: auto;
}

.button {
  margin: 40px auto 0;
}