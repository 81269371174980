@import "../../../css/utils";

.notificationsSubHeader {
  position: relative;
  overflow: visible;
  cursor: pointer;
  height: 32px;
  svg {
    path {
      fill: var(--text);
    }
  }

  .bage {
    position: absolute;
    top: 0;
    right: 0;
    height: 18px;
    width: 18px;
    border-radius: 9px;
    border: 1px solid var(--background);
    background-color: var(--error);
    color: var(--background);
    font-size: 11px;
    @include flex-center;
  }
}

.notifications {
  .items {
    max-height: 300px;
    overflow-y: scroll;
  }
}

.notifications-title,
.notifications-footer {
  height: 48px;
  @include flex-center;
}

.notifications-title {
  background-color: var(--highlight);
  color: var(--background);
}

.notifications-footer {
  color: var(--success);
  @include link-no-decoration;
}

.last-login {
  color: var(--sidebar-text);
  font-size: 10px;
  padding: 6px;
  flex: none;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  font-size: 16px;
  text-align: center;
  color: var(--text);

  @media screen and (max-width: $mobile) {
    width: unset;
  }

  &-bell {
    width: 53px;
    height: 60px;
    color: var(--highlight);
    margin-bottom: 26px;
  }
}

.arrow {
  position: absolute;
  z-index: 20;
  left: 10px;
  top: 40px;
  width: 12px;
  height: 12px;
  background-color: var(--background);
  border: 1px solid transparent;
  border-top-color: var(--background);
  border-left-color: var(--background);
  transform: rotate(45deg);
}

.bell {
  cursor: pointer;
}

@include ie11-styles {
  .last-login {
    margin-right: 30px;
  }
}
