@import "../../../../css/utils";

.sidebar {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  user-select: none;
  height: 100%;
  gap: 32px;
  cursor: pointer;
  color: var(--sidebar-text);
  @include link-no-decoration;

  @media screen and (max-width: $mobile) {
    margin-top: 24px;
  }

  a {
    font-weight: normal;
  }
}

.scroll {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-color: var(--logo-background) transparent;

  &::-webkit-scrollbar-thumb {
    background: var(--logo-background);
  }
}
