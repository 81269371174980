.collapse {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  height: auto;
  // transition: max-height 300ms;
  &.open {
    overflow: visible;
    max-height: 1000vh;
  }
}
