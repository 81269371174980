@import "../../css/utils";

.content {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;

  p {
    margin: 0 0 16px;
  }

  a {
    font-size: 16px;

    color: var(--accent);
    text-decoration: underline;
    display: block;
    margin: 4px 0 8px;

    &:hover {
      font-weight: bolder;
    }
  }
}

.consent {
  max-height: 440px;
  overflow: auto;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 40px auto 0;

  @media screen and (max-width: $mobile) {
    flex-direction: column-reverse;
    width: unset;
    height: 90px;
  }
}
