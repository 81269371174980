@import "../../css/utils";

.navigation {
  background-color: var(--background);

  .inner {
    @include standard-page-content;
    margin-bottom: 12px;
    min-height: 1px;
    display: flex;
    gap: 20px;
    box-shadow: 0 -1px var(--color-5) inset;

    > div {
      padding: 0 10px 10px 10px;
      cursor: pointer;
      text-align: center;
      line-height: 1.2;
      min-width: 100px;
      min-height: 30px;
      @include flex-center;
      @include link-no-decoration;

      &.mobile {
        padding-bottom: 10px;
      }

      &.active {
        color: var(--text);
        font-weight: bold;
        box-shadow: 0 -4px 0 0 var(--accent) inset;
      }
    }
  }
}

@include ie11-styles {
  .inner > div {
    min-height: 30px;
    margin-right: 20px;
  }
}
