.modal-wrapper {
  max-width: 400px;
}

.captcha-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vw;
  }

  .input {
    width: 100%;
    margin-top: 20px;
  }
}