:root {
    --ib-background: #f9f9f9;
    --ib-background-2: #f4f4f4;

    --ib-pure-white: #ffffff;

    --ib-cta: #138518;
    --ib-tier: #cd7f32;

    --ib-dark-default: #0e1d31;
    --ib-dark-10: #e6e8ea;
    --ib-dark-20: #cfd2d6;
    --ib-dark-40: #9fa5ad;
    --ib-dark-60: #6e7783;
    --ib-dark-80: #3e4a5a;
    --ib-dark-datepicker: #293050;

    --ib-teal-10: #e5f2f5;
    --ib-teal-40: #99cfd9;

    --ib-hover: #E7F2E7;

    --ib-base-box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.08);
    --ib-global-background: linear-gradient(95deg, #267871 0%, #136a8a 100%);
}
