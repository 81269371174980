@import "../../../css/utils";

.contact-us-info {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  line-height: 1.57;

  .title {
    font-size: 16px;

    margin-bottom: 20px;
    font-weight: bold;
  }

  .info {
    margin-top: 15px;
  }

  .email {
    @include link-no-decoration;
    overflow-wrap: anywhere;
  }
}
