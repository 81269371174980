.progress {
  width: 100%;
  height: 4px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  &.big {
    height: 10px;

    .indicator,
    .background {
      border-radius: 4px;
    }
  }

  .indicator {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 4px;
    transition: width 300ms;
    display: block;

    span {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(-45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent);
      z-index: 1;
      background-size: 50px 50px;
      animation: move 2s linear infinite;
    }
  }

  .background {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    display: block;
    background-color: var(--background-disabled);
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}