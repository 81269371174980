@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 300;
  src: url('NotoSansTC-Light.ttf');
}

@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  src: url('NotoSansTC-Regular.ttf');
}

@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSansTC-Medium.ttf');
}

@font-face {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSansTC-Bold.ttf');
}