@import "../../css/utils";

.footer {
  color: var(--color-3);
  width: 100%;
  line-height: 14px;
  font-size: 10px;
  margin-top: 22px;
  margin-bottom: 39px;
  @include colored-link;

  .stickyWarning {
    font-size: 12px;
    margin-bottom: 30px;
    background-color: var(--background);
    z-index: 200;
  }

  .inner {
    margin: 0 auto;
    @include standard-page-content;

    // margin: 0 24px;

    &.extended {
      @include out-of-platform-standard-width;
    }
  }
  .copyright,
  .version {
    text-align: center;
  }
  .comment {
    font-weight: bold;
    font-size: 16px;
    color: red;
    position: fixed;
    right: 0;
    bottom: 0;
    margin-right: 100px;
  }
}

.a a{
  text-decoration: underline;
}