@import "../../../../../css/utils";
@import "../styles";

.button {
  margin: 20px 0 0;
}

.date-and-code {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > div:nth-child(1) {
    width: 54%;
  }

  & > div:nth-child(2) {
    width: 42%;
  }
}

.smallField {
  max-width: 129px;
}

.cardInfo {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.cardNumber {
  width: 440px;
  margin-inline-end: 24px;
}

.field {
  height: 48px;
  width: 100%;
}

.apple-pay-button {
  width: 200px;
  height: 42px;
  margin: 32px auto 0;
  cursor: pointer;
  -webkit-appearance: -apple-pay-button;
}

.thinklogo {
  display: block;
  margin: 0 auto 16px;
}

.checkoutpage {
  padding: 24px 8px 16px 8px;
}

.frames {
  border-radius: 16px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  padding: 16px 14px 24px;
}

.conversionSection {
  margin: 0 0 40px;
}
