.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  &.mini {
    height: 100px;
    img {
      height: 100px;
      width: 100px;
    }
  }
  &.cover {
    position: absolute;
  }
  &.full {
    position: fixed;
  }
  &.cover,
  &.full {
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    &:before {
      content: "";
      pointer-events: none;
      display: block;
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: var(--backdrop);
    }
  }
  &.cover {
    &:before {
      position: absolute;
      backdrop-filter: blur(1px);
      -webkit-backdrop-filter: blur(1px);
      background: transparent;
    }
  }
  svg {
    z-index: 110;
  }
  .progress {
    margin-top: 50px;
    width: 90%;
  }
}
