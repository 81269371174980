@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url('NotoSans-Light.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('NotoSans-Regular.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSans-Medium.ttf');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('NotoSans-Bold.ttf');
}