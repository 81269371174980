@import "../../../css/utils";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  @include card;
  padding: 0 !important;
  margin: 0 auto;
  font-size: 16px;
  font-weight: normal;

  @include modal;
  display: flex;
  flex-direction: column;
  border: var(--modal-border);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px 16px 40px;
  background-color: var(--modal-header-background);
  color: var(--modal-header-color);

  @media screen and (max-width: $mobile) {
    padding: 16px;
  }
}

.closeHeader {
  font-size: 26px;
  font-weight: 600;
}

.title {
  margin: 0;
  align-self: flex-start;
  inline-size: 100%;
  overflow-wrap: break-word;
}

.content {
  position: relative;
  padding: 32px 40px;
  overflow: auto;

  @media screen and (max-width: $mobile) {
    padding: 24px 16px;
  }
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--box-shadow);
  backdrop-filter: blur(1px);
}

.cross {
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 1;
}

html[dir="rtl"] {
  .header {
    flex-direction: row-reverse;
  }
}

@include ie11-styles {
  .card {
    margin: 0;
  }
}
