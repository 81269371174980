@import "../../../../../../css/utils";

.warningButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 56px;
}

.noticeContent {
  text-align: start;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.71;
  color: var(--text);
}

.complete-onboarding {
  &-section {
    margin-top: 32px;
    text-align: center;
  }

  &-button {
    color: var(--accent);
    margin-left: 3px;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}