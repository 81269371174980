@import "../../../css/utils";

.sidebar {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  user-select: none;
  height: 100%;
  gap: 32px;
  cursor: pointer;
  color: var(--sidebar-text);
  @include link-no-decoration;

  @media screen and (max-width: $mobile) {
    margin-top: 24px;
  }

  a {
    font-weight: normal;
  }
}

.scroll {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-color: var(--logo-background) transparent;

  &::-webkit-scrollbar-thumb {
    background: var(--logo-background);
  }
}

.logo {
  margin-top: 40px;
  padding: 12px 16px 8px 16px;
  border-radius: 0 100px 100px 0;
  background-color: var(--color-7);
  width: 90%;

  &.mobile {
    display: none;
  }
}

.separator {
  height: 1px;
  background-color: var(--sidebar-separator);
  margin: 6px 18px;
}

html[dir="rtl"] {
  .logo {
    padding: 12px 16px 12px 24px;
    border-radius: 100px 0 0 100px;
  }
}
