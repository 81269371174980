@mixin dark-theme {
    --error: #d1425a;
    --gray: #262d30;
    --accent: #138518;
    --background: #181f25;
    --background-disabled: #4b4e52;
    --text: #f9f9f9;
    --color-2: var(--text);
    --color-3: #cfd2d6;
    --color-6: #4b4e52;
    --color-7: var(--gray);
    --highlight: #cfd2d6;
    --highlight-1: #4b4e52;
    --highlight-2: #4b4e52;
    --highlight-3: #213523;
    --contrast-background: #7d8288;
    // general inputs
    --input-border-color: #878f93;
    --input-border-color-focused: #878f93;
    //checkbox
    --checkbox-checked-background: var(--accent);
    --checkbox-checked-checkmark-color: var(--background);
    //radio
    --radio-border-color: var(--text);
    --radio-selected-color: var(--accent);
    --radio-hover: var(--text);
    //cards
    --card-banner-background: var(--highlight-1);
    --box-shadow: none;
    --active-card-border: rgba(255, 255, 255, 0.2);
    --card-border: 1px solid var(--gray);
    --login-card-background-image: linear-gradient(122deg, rgba(255, 255, 255, 0.1) 14%, rgba(255, 255, 255, 0.02) 86%);
    --card-background: var(--gray);
    --global-banner-background: var(--gray);
    --global-banner-color: var(--text);
    --alert-header-background: #3c4349;
    --alert-header-text: var(--text);
    --inside-box-background: var(--active-card);

    --modal-header-background: #3c4349;
    --modal-border: 1px solid var(--contrast-background);
    --modal-error-border: 1px solid var(--error);
    //inner cards
    --inner-card-background: var(--contrast-background);
    --inner-card-border-color: var(--contrast-background);
    --inner-card-border: 1px solid var(--inner-card-border-color);

    --global-background: linear-gradient(155deg, #0e161c 0%, #0e161c 100%);
    //select
    --selection-hover: var(--gray);
    --selection-hover-color: var(--text);
    --selected-background: var(--accent);
    --selected-color: var(--text);
    --select-out-of-form-menu-shadow: none;
    --select-out-of-form-menu-border: 1px solid var(--text);
    //buttons
    --button-hover: #89c28b;
    --button-background: var(--accent);
    --button-color: var(--text);
    --button-border: none;
    --button-disabled: rgba(255, 255, 255, 0.4);
    //sidebar
    --logo-background: #0e161c;
    --sidebar-text: var(--text);
    --sidebar-active-submenu: var(--gray);
    --sidebar-active-link-background: rgba(255, 255, 255, 0.12);
    --sidebar-active-link-color: var(--text);
    //loader
    --loader-main: var(--text);
    --loader-symbols: var(--background);
    //slider
    --slider-background: #9fa5ad;
    --slider-handle: #f9f9f9;
    --pagination-active-page: var(--accent);
    //table
    --table-header-background: #3c4349;
    --table-row-border: 1px solid #e6e8ea;
    --table-body-background: #2d353b;
    --table-hover-background: var(--table-header-background);

    --disabled-opacity: 0.8;
}
